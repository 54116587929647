<template>
  <div>
    <!-- Modal Messages-->
    <b-modal
      id="modal-messages"
      v-model="modalMessage"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Messages"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              :ref="messageListRef"
              v-if="messagesList.length > 0"
              class="list-group"
              style="height:300pxwidth:100%overflow-y:scroll"
            >
              <li
                v-for="item in messagesList"
                :key="item"
                class="list-group-item"
              >
                <div
                  v-if="userId.toString() == item.user_id.toString()"
                  class="float-right"
                >
                  <h5>Me</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
                <div
                  v-if="userId.toString() != item.user_id.toString()"
                  class="float-left"
                >
                  <h5>{{ item.user_fullname }}</h5>
                  <span style="font-size:9px">{{
                    dateSimple(
                      item.chat_created_time,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  }}</span>
                  <p>{{ item.chat_text }}</p>
                </div>
              </li>
            </ul>
            <div v-if="messagesList.length == 0">
              No message
            </div>
            <br />
            <form id="send_message_form" method="post" action="">
              <b-row>
                <b-col cols="11" md="9">
                  <input
                    v-model="messageBox"
                    type="text"
                    class="form-control"
                    placeholder="Write your message"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendMessage()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal><!-- Modal Upload-->
    <b-modal
      id="modal-upload"
      v-model="modalUpload"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Documents"
    >
      <b-form>
        <div>
          <div class="messages">
            <ul
              v-if="documentsList.length > 0"
              class="list-group"
              style="height:300px;width:100%;overflow-y:scroll"
            >
              <input
                id="materialRequestId"
                ref="invoiceMessage"
                v-model="materialRequestId"
                type="hidden"
                name="materialRequestId"
              />
              <li
  v-for="item in documentsList"
  :key="item"
  class="list-group-item d-flex justify-content-between align-items-center"
  style="cursor: pointer;"
>
  <span style="font-size:9px">{{ item.document_file_name }}</span>
  <div>
    <b-button
  :disabled="item.document_old"
  variant="primary"
  size="sm"
  class="mr-1"
  @click.stop="downloadAttachment(item)"
>
      <feather-icon icon="DownloadIcon" />
    </b-button>
    <b-button variant="danger" size="sm" @click.stop="deleteAttachment(item)">
      <feather-icon icon="TrashIcon" />
    </b-button>
  </div>
</li>
            </ul>
            <div v-if="documentsList.length == 0">
              No Files
            </div>
            <br />
            <form
              id="send_file_form"
              method="post"
              @submit.prevent="sendfiles"
              action=""
            >
              <b-row>
                <b-col cols="11" md="9">
          <b-form-file
            id="file"
            ref="uploadFile"
            placeholder="Choose one file or drop it here..."
drop-placeholder="Drop a file here..."
type="file"
@change="handleFilesUpload"
          />
          <div class="alert alert-info" role="alert">
                Max file upload is 2MB
              </div>
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendDocument()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
        <!-- Modal Remark-->
    <b-modal
      id="modal-remark"
      v-model="modalRemark"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Remark"
    >
      <b-form>
        <div>
          <div class="remark">
              <input
                id="purchaseOrderId"
                ref="remarkPO"
                v-model="purchaseOrderId"
                type="hidden"
                name="purchaseOrderId"
              />
            <form
              id="sendRemark"
              method="post"
              @submit.prevent="sendRemark"
              action=""
            >
            <b-row v-if="hasRemark==true">
                <b-col cols="12" md="12">
                  <input
                    v-model="remarkNote"
                    type="text"
                    class="form-control"
                    placeholder="Write your note"
                    readonly
                  />
                </b-col>
              </b-row>
              <b-row v-if="hasRemark==false">
                <b-col cols="11" md="9">
                  <input
                    v-model="remarkNote"
                    type="text"
                    class="form-control"
                    placeholder="Write your note"
                  />
                </b-col>
                <b-col cols="11" md="3"
                  ><input
                    type="button"
                    class="btn btn-primary pull-right"
                    value="Send"
                    @click="sendRemark()"
                /></b-col>
              </b-row>
            </form>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-tabs>
        <b-tab title="Local">
          <b-tabs>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker v-model="startDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker v-model="endDateFilter" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPO()" :disabled="isLoadingExport">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExport"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
                    <span class="text-nowrap">Add Purchase Order</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseOrderListTable"
            class="position-relative"
            :items="fetchPurchaseOrders"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_remark_note!=null
                  "
                  icon="CircleIcon"
                  class="info"
                />
                <feather-icon
                  v-if="data.item.purchase_order_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_order_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_order_number
                }}</span>
              </div>
            </template>
            <!-- Column: Purchase Receipt Number -->
            <template #cell(PurchaseRequestNumber)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
                }}</span>
              </div>
            </template>
            <!-- Column: Supplier -->
            <template #cell(Supplier)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.contact ? data.item.contact.contact_name : '-'
                }}</span>
              </div>
            </template>
            <!-- Column: With PPN -->
            <template #cell(withPPN)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
              </b-badge>
            </template>
            <!-- Column: Shipment By -->
            <template #cell(ShipmentBy)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_shipment_by }}
              </b-badge>
            </template>
            <!-- Column: Delivery Schedule -->
            <template #cell(DeliverySchedule)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  dateSimple(
                    data.item.purchase_order_delivery_schedule,
                    "YYYY-MM-DD"
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Billing Address -->
            <template #cell(BillingAddress)="data">
              {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
                data.item.branch ? data.item.branch.branch_code : "-"
              }})
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_order_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Action -->
            <template #cell(Action)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole == 'purchasing-cabang' && (data.item.purchase_order_is_EarlyFinished == false || data.item.purchase_order_is_EarlyFinished == null)" @click="earlyFinish(data.item)">
  <feather-icon icon="CheckCircleIcon" />
  <span class="align-middle ml-50">Close</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_order_update') == true &&
                      data.item.purchase_order_is_confirmed_by_laboratory_manager ==
                        null
                  "
                  @click="editPurchaseOrder(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showRemark(data.item)"
                >
                  <feather-icon icon="CircleIcon" class="info"/>
                  <span class="align-middle ml-50">Remark</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_order_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item.purchase_order_is_confirmed == null
                  "
                  @click="confirmPurchaseOrder(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseOrder(data.item.purchase_order_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                  {{ dataMeta.of }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                />
                 = Confirmed,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                />
                 = Received By Purchasing,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                = Received By Warehouse,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalPurchaseOrder"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinished" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinished" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPOFinished()" :disabled="isLoadingExportFinished">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportFinished"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinished"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinished"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
                    <span class="text-nowrap">Add Purchase Order</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseOrderListTableFinished"
            class="position-relative"
            :items="fetchPurchaseOrdersFinished"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortByFinished"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescFinished"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_remark_note!=null
                  "
                  icon="CircleIcon"
                  class="info"
                />
                <feather-icon
                  v-if="data.item.purchase_order_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_order_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_order_number
                }}</span>
              </div>
            </template>
            <!-- Column: Purchase Receipt Number -->
            <template #cell(PurchaseRequestNumber)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
                }}</span>
              </div>
            </template>
            <!-- Column: Supplier -->
            <template #cell(Supplier)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.contact ? data.item.contact.contact_name : '-'
                }}</span>
              </div>
            </template>
            <!-- Column: With PPN -->
            <template #cell(withPPN)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
              </b-badge>
            </template>
            <!-- Column: Shipment By -->
            <template #cell(ShipmentBy)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_shipment_by }}
              </b-badge>
            </template>
            <!-- Column: Delivery Schedule -->
            <template #cell(DeliverySchedule)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  dateSimple(
                    data.item.purchase_order_delivery_schedule,
                    "YYYY-MM-DD"
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Billing Address -->
            <template #cell(BillingAddress)="data">
              {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
                data.item.branch ? data.item.branch.branch_code : "-"
              }})
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_order_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Action -->
            <template #cell(Action)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole == 'purchasing-cabang' && (data.item.purchase_order_is_EarlyFinished == false || data.item.purchase_order_is_EarlyFinished == null)" @click="earlyFinish(data.item)">
  <feather-icon icon="CheckCircleIcon" />
  <span class="align-middle ml-50">Close</span>
</b-dropdown-item>
                <b-dropdown-item
                  @click="showRemark(data.item)"
                >
                  <feather-icon icon="CircleIcon" class="info"/>
                  <span class="align-middle ml-50">Remark</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_order_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item.purchase_order_is_confirmed == null
                  "
                  @click="confirmPurchaseOrder(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseOrder(data.item.purchase_order_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaFinished.from }} to {{ dataMetaFinished.to }} of
                  {{ dataMetaFinished.of }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                />
                 = Confirmed,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                />
                 = Received By Purchasing,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                = Received By Warehouse,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinished"
                  :total-rows="totalPurchaseOrderFinished"
                  :per-page="perPageFinished"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        </b-tabs>
        </b-tab>
        <b-tab title="Head Office">
          <b-tabs>
        <b-tab title="On Going">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker v-model="startDateFilterHO" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker v-model="endDateFilterHO" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPOHO()" :disabled="isLoadingExportHO">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportHO"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageHO"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryHO"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
                    <span class="text-nowrap">Add Purchase Order</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseOrderListTableHO"
            class="position-relative"
            :items="fetchPurchaseOrdersHO"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortByHO"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescHO"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_remark_note!=null
                  "
                  icon="CircleIcon"
                  class="info"
                />
                <feather-icon
                  v-if="data.item.purchase_order_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_order_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_order_number
                }}</span>
              </div>
            </template>
            <!-- Column: Purchase Receipt Number -->
            <template #cell(PurchaseRequestNumber)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
                }}</span>
              </div>
            </template>
            <!-- Column: Supplier -->
            <template #cell(Supplier)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.contact ? data.item.contact.contact_name : '-'
                }}</span>
              </div>
            </template>
            <!-- Column: With PPN -->
            <template #cell(withPPN)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
              </b-badge>
            </template>
            <!-- Column: Shipment By -->
            <template #cell(ShipmentBy)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_shipment_by }}
              </b-badge>
            </template>
            <!-- Column: Delivery Schedule -->
            <template #cell(DeliverySchedule)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  dateSimple(
                    data.item.purchase_order_delivery_schedule,
                    "YYYY-MM-DD"
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Billing Address -->
            <template #cell(BillingAddress)="data">
              {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
                data.item.branch ? data.item.branch.branch_code : "-"
              }})
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_order_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Action -->
            <template #cell(Action)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="userRole == 'purchasing-cabang' && (data.item.purchase_order_is_EarlyFinished == false || data.item.purchase_order_is_EarlyFinished == null)" @click="earlyFinish(data.item)">
  <feather-icon icon="CheckCircleIcon" />
  <span class="align-middle ml-50">Close</span>
</b-dropdown-item>
          <b-dropdown-item
                  v-if="
                    hasPermission('purchase_order_update') == true &&
                      data.item.purchase_order_is_confirmed_by_laboratory_manager ==
                        null
                  "
                  @click="editPurchaseOrder(data.item)"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showRemark(data.item)"
                >
                  <feather-icon icon="CircleIcon" class="info"/>
                  <span class="align-middle ml-50">Remark</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_order_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item.purchase_order_is_confirmed == null
                  "
                  @click="confirmPurchaseOrder(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseOrder(data.item.purchase_order_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaHO.from }} to {{ dataMetaHO.to }} of
                  {{ dataMetaHO.of }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                />
                 = Confirmed,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                />
                 = Received By Purchasing,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                = Received By Warehouse,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageHO"
                  :total-rows="totalPurchaseOrderHO"
                  :per-page="perPageHO"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab title="Finished">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-finished" v-model="startDateFilterFinished" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-finished" v-model="endDateFilterFinished" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPOFinished()" :disabled="isLoadingExportFinished">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportFinished"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageFinished"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryFinishedHO"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
                    <span class="text-nowrap">Add Purchase Order</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseOrderListTableFinishedHO"
            class="position-relative"
            :items="fetchPurchaseOrdersFinishedHO"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortByFinishedHO"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescFinishedHO"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_remark_note!=null
                  "
                  icon="CircleIcon"
                  class="info"
                />
                <feather-icon
                  v-if="data.item.purchase_order_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_order_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_order_number
                }}</span>
              </div>
            </template>
            <!-- Column: Purchase Receipt Number -->
            <template #cell(PurchaseRequestNumber)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
                }}</span>
              </div>
            </template>
            <!-- Column: Supplier -->
            <template #cell(Supplier)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.contact ? data.item.contact.contact_name : '-'
                }}</span>
              </div>
            </template>
            <!-- Column: With PPN -->
            <template #cell(withPPN)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
              </b-badge>
            </template>
            <!-- Column: Shipment By -->
            <template #cell(ShipmentBy)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_shipment_by }}
              </b-badge>
            </template>
            <!-- Column: Delivery Schedule -->
            <template #cell(DeliverySchedule)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  dateSimple(
                    data.item.purchase_order_delivery_schedule,
                    "YYYY-MM-DD"
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Billing Address -->
            <template #cell(BillingAddress)="data">
              {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
                data.item.branch ? data.item.branch.branch_code : "-"
              }})
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_order_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
            <!-- Column: Action -->
            <template #cell(Action)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="userRole == 'purchasing-cabang' && (data.item.purchase_order_is_EarlyFinished == false || data.item.purchase_order_is_EarlyFinished == null)" @click="earlyFinish(data.item)">
  <feather-icon icon="CheckCircleIcon" />
  <span class="align-middle ml-50">Close</span>
</b-dropdown-item>
                <b-dropdown-item
                  @click="showRemark(data.item)"
                >
                  <feather-icon icon="CircleIcon" class="info"/>
                  <span class="align-middle ml-50">Remark</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showMessages(data.item)"
                >
                  <feather-icon icon="MessageCircleIcon" />
                  <span class="align-middle ml-50">Chat</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDocuments(data.item.purchase_order_id)"
                >
                  <feather-icon icon="FileIcon" />
                  <span class="align-middle ml-50">Attachments</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    hasPermission('purchase_request_confirm_lab_manager') == true &&
                      data.item.purchase_order_is_confirmed == null
                  "
                  @click="confirmPurchaseOrder(data.item)"
                >
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Confirm</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="userRole=='administrator'"
                  @click="deletePurchaseOrder(data.item.purchase_order_id)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaFinishedHO.from }} to {{ dataMetaFinishedHO.to }} of
                  {{ dataMetaFinishedHO.of }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                />
                 = Confirmed,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                />
                 = Received By Purchasing,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                = Received By Warehouse,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinishedHO"
                  :total-rows="totalPurchaseOrderFinishedHO"
                  :per-page="perPageFinishedHO"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        </b-tabs>
        </b-tab>
        <b-tab title="Void">
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <b-col cols="5">
                <label>Tanggal Awal</label>
                <b-form-datepicker id="start-datepicker-void" v-model="startDateFilterVoid" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="5">
                <label>Tanggal Akhir</label>
                <b-form-datepicker id="end-datepicker-void" v-model="endDateFilterVoid" class="mb-2"></b-form-datepicker>
              </b-col>
              <b-col cols="2" class="d-flex align-items-center justify-content-center">
                <b-button variant="primary" @click="exportPOVoid()" :disabled="isLoadingExportVoid">
                  <feather-icon icon="DownloadIcon" /> Export
                  <b-spinner small type="grow" v-if="isLoadingExportVoid"></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPageVoid"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQueryVoid"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
                    <span class="text-nowrap">Add Purchase Order</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-table
            ref="refPurchaseOrderListTableVoid"
            class="position-relative"
            :items="fetchPurchaseOrdersVoid"
            responsive
            :fields="tableColumnsVoid"
            primary-key="id"
            :sort-by.sync="sortByVoid"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDescVoid"
          >
            <!-- Column: Number -->
            <template #cell(Number)="data">
              <div class="text-nowrap">
                <feather-icon
                  v-if="data.item.chats !=null"
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_remark_note!=null
                  "
                  icon="CircleIcon"
                  class="info"
                />
                <feather-icon
                  v-if="data.item.purchase_order_is_confirmed == null"
                  icon="CircleIcon"
                  class="default"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == null
                  "
                  icon="CircleIcon"
                  class="danger"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == null
                  "
                  icon="CircleIcon"
                  class="warning"
                />
                <feather-icon
                  v-if="
                    data.item.purchase_order_is_confirmed == true &&
                      data.item.purchase_order_is_received_by_purchasing == true &&
                      data.item.purchase_order_is_received_by_warehouse == true
                  "
                  icon="CircleIcon"
                  class="success"
                />
                <feather-icon
                v-if="data.item.purchase_order_has_files == true"
                icon="FileIcon" />
                &nbsp;
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_order_number
                }}</span>
              </div>
            </template>
            <!-- Column: Purchase Receipt Number -->
            <template #cell(PurchaseRequestNumber)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
                }}</span>
              </div>
            </template>
            <!-- Column: Supplier -->
            <template #cell(Supplier)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  data.item.contact ? data.item.contact.contact_name : '-'
                }}</span>
              </div>
            </template>
            <!-- Column: With PPN -->
            <template #cell(withPPN)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
              </b-badge>
            </template>
            <!-- Column: Shipment By -->
            <template #cell(ShipmentBy)="data">
              <b-badge pill class="text-capitalize">
                {{ data.item.purchase_order_shipment_by }}
              </b-badge>
            </template>
            <!-- Column: Delivery Schedule -->
            <template #cell(DeliverySchedule)="data">
              <div class="text-nowrap">
                <span class="align-text-top text-capitalize">{{
                  dateSimple(
                    data.item.purchase_order_delivery_schedule,
                    "YYYY-MM-DD"
                  )
                }}</span>
              </div>
            </template>
            <!-- Column: Billing Address -->
            <template #cell(BillingAddress)="data">
              {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
                data.item.branch ? data.item.branch.branch_code : "-"
              }})
            </template>
            <!-- Column: DateCreated -->
            <template #cell(DateCreated)="data">
              {{
                dateSimple(
                  data.item.purchase_order_created_time,
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </template>
        <!-- Column: VoidBy -->
        <template #cell(VoidBy)="data">
          <b-badge pill class="text-capitalize">
            {{
              data.item.deleter
                ? data.item.deleter.user_fullname
                : "-"
            }}
          </b-badge>
        </template>
        <!-- Column: VoidTime -->
        <template #cell(VoidTime)="data">
          {{
            data.item.purchase_order_deleted_time?
            dateSimple(
              data.item.purchase_order_deleted_time,
              "YYYY-MM-DD HH:mm:ss"
            ):"-"
          }}
        </template>
            <!-- Column: Action -->
            <template #cell(Action)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="showDetail(data.item)">
                  <feather-icon icon="ZoomInIcon" />
                  <span class="align-middle ml-50">Detail</span>
                </b-dropdown-item>
                <b-dropdown-item v-if="userRole == 'purchasing-cabang' && (data.item.purchase_order_is_EarlyFinished == false || data.item.purchase_order_is_EarlyFinished == null)" @click="earlyFinish(data.item)">
  <feather-icon icon="CheckCircleIcon" />
  <span class="align-middle ml-50">Close</span>
</b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ dataMetaVoid.from }} to {{ dataMetaVoid.to }} of
                  {{ dataMetaVoid.of }} entries</span
                >
                <span style="padding-left: 10px;">
                <feather-icon
                  icon="CircleIcon"
                  class="default"
                /> = Just Created,
                <feather-icon
                  icon="CircleIcon"
                  class="danger"
                />
                 = Confirmed,
                <feather-icon
                  icon="CircleIcon"
                  class="warning"
                />
                 = Received By Purchasing,
                <feather-icon
                  icon="CircleIcon"
                  class="success"
                />
                = Received By Warehouse,
                <feather-icon
                  style="color:#036bfc"
                  icon="MessageCircleIcon"
                /> = Message,
                <feather-icon
                icon="FileIcon" /> = Attachment
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageVoid"
                  :total-rows="totalPurchaseOrderVoid"
                  :per-page="perPageVoid"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>

<b-tab title="Close">
<b-tabs>
<b-tab title="Needs to Confirm">
<div class="m-2">
  <!-- Table Top -->
  <b-row>
    <b-col cols="5">
      <label>Tanggal Awal</label>
      <b-form-datepicker v-model="startDateFilterEarlyFinishedNC" class="mb-2"></b-form-datepicker>
    </b-col>
    <b-col cols="5">
      <label>Tanggal Akhir</label>
      <b-form-datepicker  v-model="endDateFilterEarlyFinishedNC" class="mb-2"></b-form-datepicker>
    </b-col>
    <b-col cols="2" class="d-flex align-items-center justify-content-center">
      <b-button variant="primary" @click="exportPOHO()" :disabled="isLoadingExportEarlyFinishedNC">
        <feather-icon icon="DownloadIcon" /> Export
        <b-spinner small type="grow" v-if="isLoadingExportEarlyFinishedNC"></b-spinner>
      </b-button>
    </b-col>
  </b-row>
  <b-row>
    <!-- Per Page -->
    <b-col
      cols="12"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <label>Show</label>
      <v-select
        v-model="perPageEarlyFinishedNC"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50"
      />
      <label>entries</label>
    </b-col>
    <b-col cols="12" md="6">
      <div class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQueryEarlyFinishedNC"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
          <span class="text-nowrap">Add Purchase Order</span>
        </b-button>
      </div>
    </b-col>
  </b-row>
</div>
<b-table
  ref="refPurchaseOrderListTableEarlyFinishedNC"
  class="position-relative"
  :items="fetchPurchaseOrdersEarlyFinishedNC"
  responsive
  :fields="tableColumns"
  primary-key="id"
  :sort-by.sync="sortByEarlyFinishedNC"
  show-empty
  empty-text="No matching records found"
  :sort-desc.sync="isSortDirDescEarlyFinishedNC"
>
  <!-- Column: Number -->
  <template #cell(Number)="data">
    <div class="text-nowrap">
      <feather-icon
        v-if="data.item.chats !=null"
        style="color:#036bfc"
        icon="MessageCircleIcon"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_remark_note!=null
        "
        icon="CircleIcon"
        class="info"
      />
      <feather-icon
        v-if="data.item.purchase_order_is_confirmed == null"
        icon="CircleIcon"
        class="default"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_is_confirmed == true &&
            data.item.purchase_order_is_received_by_purchasing == null
        "
        icon="CircleIcon"
        class="danger"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_is_confirmed == true &&
            data.item.purchase_order_is_received_by_purchasing == true &&
            data.item.purchase_order_is_received_by_warehouse == null
        "
        icon="CircleIcon"
        class="warning"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_is_confirmed == true &&
            data.item.purchase_order_is_received_by_purchasing == true &&
            data.item.purchase_order_is_received_by_warehouse == true
        "
        icon="CircleIcon"
        class="success"
      />
      <feather-icon
      v-if="data.item.purchase_order_has_files == true"
      icon="FileIcon" />
      &nbsp;
      <span class="align-text-top text-capitalize">{{
        data.item.purchase_order_number
      }}</span>
    </div>
  </template>
  <!-- Column: Purchase Receipt Number -->
  <template #cell(PurchaseRequestNumber)="data">
    <div class="text-nowrap">
      <span class="align-text-top text-capitalize">{{
        data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
      }}</span>
    </div>
  </template>
  <!-- Column: Supplier -->
  <template #cell(Supplier)="data">
    <div class="text-nowrap">
      <span class="align-text-top text-capitalize">{{
        data.item.contact ? data.item.contact.contact_name : '-'
      }}</span>
    </div>
  </template>
  <!-- Column: With PPN -->
  <template #cell(withPPN)="data">
    <b-badge pill class="text-capitalize">
      {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
    </b-badge>
  </template>
  <!-- Column: Shipment By -->
  <template #cell(ShipmentBy)="data">
    <b-badge pill class="text-capitalize">
      {{ data.item.purchase_order_shipment_by }}
    </b-badge>
  </template>
  <!-- Column: Delivery Schedule -->
  <template #cell(DeliverySchedule)="data">
    <div class="text-nowrap">
      <span class="align-text-top text-capitalize">{{
        dateSimple(
          data.item.purchase_order_delivery_schedule,
          "YYYY-MM-DD"
        )
      }}</span>
    </div>
  </template>
  <!-- Column: Billing Address -->
  <template #cell(BillingAddress)="data">
    {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
      data.item.branch ? data.item.branch.branch_code : "-"
    }})
  </template>
  <!-- Column: DateCreated -->
  <template #cell(DateCreated)="data">
    {{
      dateSimple(
        data.item.purchase_order_created_time,
        "YYYY-MM-DD HH:mm:ss"
      )
    }}
  </template>
  <!-- Column: Action -->
  <template #cell(Action)="data">
    <b-dropdown
      variant="link"
      no-caret
      :right="$store.state.appConfig.isRTL"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="16"
          class="align-middle text-body"
        />
      </template>

      <b-dropdown-item @click="showDetail(data.item)">
        <feather-icon icon="ZoomInIcon" />
        <span class="align-middle ml-50">Detail</span>
      </b-dropdown-item>
<b-dropdown-item v-if="data.item.purchase_order_is_finished == null && data.item.purchase_order_is_early_finished != null && (userRole=='laboratory-manager' || userRole=='lab-manager')" @click="confirmEarlyFinish(data.item)">
  <feather-icon icon="CheckCircleIcon" />
  <span class="align-middle ml-50">Confirm Close</span>
</b-dropdown-item>
<b-dropdown-item
        v-if="
          hasPermission('purchase_order_update') == true &&
            data.item.purchase_order_is_confirmed_by_laboratory_manager ==
              null
        "
        @click="editPurchaseOrder(data.item)"
      >
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="showRemark(data.item)"
      >
        <feather-icon icon="CircleIcon" class="info"/>
        <span class="align-middle ml-50">Remark</span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="showMessages(data.item)"
      >
        <feather-icon icon="MessageCircleIcon" />
        <span class="align-middle ml-50">Chat</span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="showDocuments(data.item.purchase_order_id)"
      >
        <feather-icon icon="FileIcon" />
        <span class="align-middle ml-50">Attachments</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="userRole=='administrator'"
        @click="deletePurchaseOrder(data.item.purchase_order_id)"
      >
        <feather-icon icon="TrashIcon" />
        <span class="align-middle ml-50">Delete</span>
      </b-dropdown-item>
    </b-dropdown>
  </template>
</b-table>
<div class="mx-2 mb-2">
  <b-row>
    <b-col
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-start"
    >
      <span class="text-muted"
        >Showing {{ dataMetaHO.from }} to {{ dataMetaHO.to }} of
        {{ dataMetaHO.of }} entries</span
      >
      <span style="padding-left: 10px;">
      <feather-icon
        icon="CircleIcon"
        class="default"
      /> = Just Created,
      <feather-icon
        icon="CircleIcon"
        class="danger"
      />
       = Confirmed,
      <feather-icon
        icon="CircleIcon"
        class="warning"
      />
       = Received By Purchasing,
      <feather-icon
        icon="CircleIcon"
        class="success"
      />
      = Received By Warehouse,
      <feather-icon
        style="color:#036bfc"
        icon="MessageCircleIcon"
      /> = Message,
      <feather-icon
      icon="FileIcon" /> = Attachment
      </span>
    </b-col>
    <!-- Pagination -->
    <b-col
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-end"
    >
      <b-pagination
        v-model="currentPageEarlyFinishedNC"
        :total-rows="totalPurchaseOrderEarlyFinishedNC"
        :per-page="perPageEarlyFinishedNC"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</div>
</b-tab>
<b-tab title="Closed">
<div class="m-2">
  <!-- Table Top -->
  <b-row>
    <b-col cols="5">
      <label>Tanggal Awal</label>
      <b-form-datepicker id="start-datepicker-EarlyFinished" v-model="startDateFilterEarlyFinished" class="mb-2"></b-form-datepicker>
    </b-col>
    <b-col cols="5">
      <label>Tanggal Akhir</label>
      <b-form-datepicker id="end-datepicker-EarlyFinished" v-model="endDateFilterEarlyFinished" class="mb-2"></b-form-datepicker>
    </b-col>
    <b-col cols="2" class="d-flex align-items-center justify-content-center">
      <b-button variant="primary" @click="exportPOEarlyFinished()" :disabled="isLoadingExportEarlyFinished">
        <feather-icon icon="DownloadIcon" /> Export
        <b-spinner small type="grow" v-if="isLoadingExportEarlyFinished"></b-spinner>
      </b-button>
    </b-col>
  </b-row>
  <b-row>
    <!-- Per Page -->
    <b-col
      cols="12"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <label>Show</label>
      <v-select
        v-model="perPageEarlyFinished"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50"
      />
      <label>entries</label>
    </b-col>
    <b-col cols="12" md="6">
      <div class="d-flex align-items-center justify-content-end">
        <b-form-input
          v-model="searchQueryEarlyFinishedNC"
          class="d-inline-block mr-1"
          placeholder="Search..."
        />
        <b-button variant="primary" @click="addNew()" v-if="userRole=='super-admin' || userRole=='admin' || userRole=='purchasing-cabang'">
          <span class="text-nowrap">Add Purchase Order</span>
        </b-button>
      </div>
    </b-col>
  </b-row>
</div>
<b-table
  ref="refPurchaseOrderListTableEarlyFinished"
  class="position-relative"
  :items="fetchPurchaseOrdersEarlyFinished"
  responsive
  :fields="tableColumns"
  primary-key="id"
  :sort-by.sync="sortByEarlyFinishedNC"
  show-empty
  empty-text="No matching records found"
  :sort-desc.sync="isSortDirDescEarlyFinished"
>
  <!-- Column: Number -->
  <template #cell(Number)="data">
    <div class="text-nowrap">
      <feather-icon
        v-if="data.item.chats !=null"
        style="color:#036bfc"
        icon="MessageCircleIcon"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_remark_note!=null
        "
        icon="CircleIcon"
        class="info"
      />
      <feather-icon
        v-if="data.item.purchase_order_is_confirmed == null"
        icon="CircleIcon"
        class="default"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_is_confirmed == true &&
            data.item.purchase_order_is_received_by_purchasing == null
        "
        icon="CircleIcon"
        class="danger"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_is_confirmed == true &&
            data.item.purchase_order_is_received_by_purchasing == true &&
            data.item.purchase_order_is_received_by_warehouse == null
        "
        icon="CircleIcon"
        class="warning"
      />
      <feather-icon
        v-if="
          data.item.purchase_order_is_confirmed == true &&
            data.item.purchase_order_is_received_by_purchasing == true &&
            data.item.purchase_order_is_received_by_warehouse == true
        "
        icon="CircleIcon"
        class="success"
      />
      <feather-icon
      v-if="data.item.purchase_order_has_files == true"
      icon="FileIcon" />
      &nbsp;
      <span class="align-text-top text-capitalize">{{
        data.item.purchase_order_number
      }}</span>
    </div>
  </template>
  <!-- Column: Purchase Receipt Number -->
  <template #cell(PurchaseRequestNumber)="data">
    <div class="text-nowrap">
      <span class="align-text-top text-capitalize">{{
        data.item.purchase_request!=null?data.item.purchase_request.purchase_request_number:"-"
      }}</span>
    </div>
  </template>
  <!-- Column: Supplier -->
  <template #cell(Supplier)="data">
    <div class="text-nowrap">
      <span class="align-text-top text-capitalize">{{
        data.item.contact ? data.item.contact.contact_name : '-'
      }}</span>
    </div>
  </template>
  <!-- Column: With PPN -->
  <template #cell(withPPN)="data">
    <b-badge pill class="text-capitalize">
      {{ data.item.purchase_order_is_with_tax==true ? "Yes" : "No" }}
    </b-badge>
  </template>
  <!-- Column: Shipment By -->
  <template #cell(ShipmentBy)="data">
    <b-badge pill class="text-capitalize">
      {{ data.item.purchase_order_shipment_by }}
    </b-badge>
  </template>
  <!-- Column: Delivery Schedule -->
  <template #cell(DeliverySchedule)="data">
    <div class="text-nowrap">
      <span class="align-text-top text-capitalize">{{
        dateSimple(
          data.item.purchase_order_delivery_schedule,
          "YYYY-MM-DD"
        )
      }}</span>
    </div>
  </template>
  <!-- Column: Billing Address -->
  <template #cell(BillingAddress)="data">
    {{ data.item.branch ? data.item.branch.branch_city : "-" }} ({{
      data.item.branch ? data.item.branch.branch_code : "-"
    }})
  </template>
  <!-- Column: DateCreated -->
  <template #cell(DateCreated)="data">
    {{
      dateSimple(
        data.item.purchase_order_created_time,
        "YYYY-MM-DD HH:mm:ss"
      )
    }}
  </template>
  <!-- Column: Action -->
  <template #cell(Action)="data">
    <b-dropdown
      variant="link"
      no-caret
      :right="$store.state.appConfig.isRTL"
    >
      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="16"
          class="align-middle text-body"
        />
      </template>

      <b-dropdown-item @click="showDetail(data.item)">
        <feather-icon icon="ZoomInIcon" />
        <span class="align-middle ml-50">Detail</span>
      </b-dropdown-item>

      <b-dropdown-item v-if="userRole == 'purchasing-cabang' && (data.item.purchase_order_is_EarlyFinished == false || data.item.purchase_order_is_EarlyFinished == null)" @click="earlyFinish(data.item)">
<feather-icon icon="CheckCircleIcon" />
<span class="align-middle ml-50">Close</span>
</b-dropdown-item>
      <b-dropdown-item
        @click="showRemark(data.item)"
      >
        <feather-icon icon="CircleIcon" class="info"/>
        <span class="align-middle ml-50">Remark</span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="showMessages(data.item)"
      >
        <feather-icon icon="MessageCircleIcon" />
        <span class="align-middle ml-50">Chat</span>
      </b-dropdown-item>
      <b-dropdown-item
        @click="showDocuments(data.item.purchase_order_id)"
      >
        <feather-icon icon="FileIcon" />
        <span class="align-middle ml-50">Attachments</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="
          hasPermission('purchase_request_confirm_lab_manager') == true &&
            data.item.purchase_order_is_confirmed == null
        "
        @click="confirmPurchaseOrder(data.item)"
      >
        <feather-icon icon="CheckIcon" />
        <span class="align-middle ml-50">Confirm</span>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="userRole=='administrator'"
        @click="deletePurchaseOrder(data.item.purchase_order_id)"
      >
        <feather-icon icon="TrashIcon" />
        <span class="align-middle ml-50">Delete</span>
      </b-dropdown-item>
    </b-dropdown>
  </template>
</b-table>
<div class="mx-2 mb-2">
  <b-row>
    <b-col
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-start"
    >
      <span class="text-muted"
        >Showing {{ dataMetaEarlyFinishedNC.from }} to {{ dataMetaEarlyFinishedNC.to }} of
        {{ dataMetaEarlyFinishedNC.of }} entries</span
      >
      <span style="padding-left: 10px;">
      <feather-icon
        icon="CircleIcon"
        class="default"
      /> = Just Created,
      <feather-icon
        icon="CircleIcon"
        class="danger"
      />
       = Confirmed,
      <feather-icon
        icon="CircleIcon"
        class="warning"
      />
       = Received By Purchasing,
      <feather-icon
        icon="CircleIcon"
        class="success"
      />
      = Received By Warehouse,
      <feather-icon
        style="color:#036bfc"
        icon="MessageCircleIcon"
      /> = Message,
      <feather-icon
      icon="FileIcon" /> = Attachment
      </span>
    </b-col>
    <!-- Pagination -->
    <b-col
      cols="12"
      sm="6"
      class="d-flex align-items-center justify-content-center justify-content-sm-end"
    >
      <b-pagination
        v-model="currentPageEarlyFinished"
        :total-rows="totalPurchaseOrderEarlyFinished"
        :per-page="perPageEarlyFinished"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</div>
</b-tab>
</b-tabs>
</b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BTabs,
  BTab,
  BFormDatepicker,
  BForm,
  BFormFile,
} from "bootstrap-vue"
import axios from "axios"
import vSelect from "vue-select"
import store from "@/store"
import { getUserData } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { ref, onUnmounted } from "@vue/composition-api"
import { avatarText } from "@core/utils/filter"
import moment from "moment"
import { VueHorizontalTimeline } from "vue-horizontal-timeline"
import { saveAs } from 'file-saver'
import PurchaseOrderList from "./List"

const userRole = ""
const messageBox = ""
const to = ""
const cc = ""
const reason = ""
const PurchaseOrderId = ""
const messagesList = []
const statusItems = []
const items = []
const userId = getUserData().user_id
const modalDetailShow = false
const modalMessage = false
const hasRemark = false
const modalRemark = false
const modalUpload = false
const remarkNote = ''
const documentsList = []
export default {
  components: {
    BFormFile,
    VueHorizontalTimeline,
    BListGroup,
    BListGroupItem,
    // PurchaseOrderListFilters,
    BModal,
    VBModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTabs,
    BTab,
    BFormDatepicker,
    BForm,
  },
  data() {
    return {
      remarkNote,
      hasRemark,
      modalRemark,
      modalUpload,
      documentsList,
      items,
      statusItems,
      PurchaseOrderId,
      userId,
      messagesList,
      isLoadingExport: false,
      isLoadingExportHO: false,
      isLoadingExportFinished: false,
      isLoadingExportEarlyFinished: false,
      isLoadingExportEarlyFinishedNC: false,
      isLoadingExportFinishedHO: false,
      isLoadingExportVoid: false,
      userdata: getUserData(),
      messageListRef: 1,
    }
  },
  mounted() {
    this.userRole = this.$cookies.get("UserRole")
    this.$root.$on("refreshTable", text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refPurchaseOrderListTable.refresh()
      this.$refs.refPurchaseOrderListTableFinished.refresh()
      this.$refs.refPurchaseOrderListTableEarlyFinished.refresh()
      this.$refs.refPurchaseOrderListTableEarlyFinishedNC.refresh()
      this.$refs.refPurchaseOrderListTableVoid.refresh()
    })
  },
  methods: {
    exportPOHO() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_ORDER}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
            is_center: true,
            type: "ongoing",
          },
      }).then(response => {
        let filename = "report_purchase_order"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExportHO = false
      })
    },
    exportPO() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_ORDER}`
      this.isLoadingExport = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilter,
            date_end: this.endDateFilter,
            type: "ongoing",
          },
      }).then(response => {
        let filename = "report_purchase_order"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExport = false
      })
    },
    exportPOFinishedHO() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_ORDER}`
      this.isLoadingExportFinished = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilterFinished,
            date_end: this.endDateFilterFinished,
            is_center: true,
            type: "finished",
          },
      }).then(response => {
        let filename = "report_purchase_order"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExportFinishedHO = false
      })
    },
    exportPOFinished() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_ORDER}`
      this.isLoadingExportFinished = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilterFinished,
            date_end: this.endDateFilterFinished,
            type: "finished",
          },
      }).then(response => {
        let filename = "report_purchase_order"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    exportPOVoid() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EXPORT_PURCHASE_ORDER}`
      this.isLoadingExportVoid = true

      axios.get(url, {
          headers,
          responseType: 'arraybuffer',
          params: {
            date_start: this.startDateFilterVoid,
            date_end: this.endDateFilterVoid,
            type: "finished",
          },
      }).then(response => {
        let filename = "report_purchase_order"
        if (this.startDateFilter) {
          filename += `_${this.startDateFilter}`
        }
        if (this.endDateFilter) {
          filename += `_${this.endDateFilter}`
        }
        filename += ".xlsx"
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] })

        // Trigger the download using FileSaver.js
        saveAs(blob, filename)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Export Success",
            icon: "AlertTriangleIcon",
            variant: "success",
          },
        })
      }).finally(() => {
        this.isLoadingExportFinished = false
      })
    },
    deletePurchaseOrder(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .get(`${process.env.VUE_APP_API_DELETE_PURCHASE_ORDER}${id}`, {
          headers,
        })
        .then(response => {
          console.log(response)
          if (response.data.success === true) {
            this.$refs.refPurchaseOrderListTable.refresh()
            this.$refs.refPurchaseOrderListTableHO.refresh()
            this.$refs.refPurchaseOrderListTableFinished.refresh()
            this.$refs.refPurchaseOrderListTableFinishedHO.refresh()
            this.$refs.refPurchaseOrderListTableEarlyFinished.refresh()
            this.$refs.refPurchaseOrderListTableEarlyFinishedNC.refresh()
            this.$refs.refPurchaseOrderListTableVoid.refresh()
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Congratulation",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Your data has been deleted!",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Notification",
                icon: "BellIcon",
                variant: "danger",
                text: "Something went wrong",
              },
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    editPurchaseOrder(data) {
      localStorage.setItem("podetail", JSON.stringify(data))
      this.$router.push({ name: "apps-purchase-order-edit" })
    },
    confirmPurchaseOrder(data) {
      localStorage.setItem("podetail", JSON.stringify(data))
      this.$router.push({ name: "apps-purchase-order-confirm" })
    },
    sendPurchaseOrder(data) {
      localStorage.setItem("podetail", JSON.stringify(data))
      this.$router.push({ name: "apps-purchase-order-send" })
    },
    addNew() {
      this.$router.push({ name: "apps-purchase-order-create" })
    },
    dateSimple(value, format = "YYYY-MM-DD") {
      let dateRet = ""
      if (value !== null) {
        dateRet = moment(String(value)).format(format)
      } else {
        dateRet = null
      }
      return dateRet
    },
    showDetail(item) {
      localStorage.setItem("podetail", JSON.stringify(item))
      this.$router.push({ name: "apps-purchase-order-detail" })
    },
    hasPermission(permission) {
      const permissions = this.$cookies.get("userPermissions").split(",")
      return permissions.includes(permission) ? true : false
    },
    async receivePurchasingPurchaseOrder(item) {
      localStorage.setItem("podetail", JSON.stringify(item))
      this.$router.push({ name: "apps-purchase-order-receive-purchasing" })
    },
    async receiveWarehousePurchaseOrder(item) {
      localStorage.setItem("podetail", JSON.stringify(item))
      this.$router.push({ name: "apps-purchase-order-receive-warehouse" })
    },
    showRemark(purchaseOrder) {
      this.remarkNote = purchaseOrder.purchase_order_remark_note
      this.purchaseOrderId = purchaseOrder.purchase_order_id
      this.modalRemark = true
      this.hasRemark = purchaseOrder.purchase_order_remark_note ? true : false
    },
    showMessages(purchaseOrder) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.modalMessage = true
      this.purchaseOrderId = purchaseOrder.purchase_order_id
      this.messagesList.splice(0, this.messagesList.length)
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_SINGLE}${this.purchaseOrderId}`,
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            console.log(response.data)
            if (response.data.data.chats) {
              this.messagesList = JSON.parse(response.data.data.chats)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    async sendRemark() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PURCHASE_ORDER_SEND_REMARK}`,
          {
            purchase_order_id: this.purchaseOrderId,
            purchase_order_remark_note: this.remarkNote,
            time: moment(),
          },
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
            this.modalRemark = false
              this.$refs.refPurchaseOrderListTable.refresh()
              this.$refs.refPurchaseOrderListTableFinished.refresh()
              this.$refs.refPurchaseOrderListTableEarlyFinished.refresh()
              this.$refs.refPurchaseOrderListTableEarlyFinishedNC.refresh()
              this.$refs.refPurchaseOrderListTableVoid.refresh()
            // this.ref.messageListRef.refresh()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        }).finally(() => {
          this.messageBox = ""
        })
    },
    async earlyFinish(data) {
      localStorage.setItem("podetail", JSON.stringify(data))
      this.$router.push({ name: "apps-purchase-order-early-finish" })
    },
    async confirmEarlyFinish(data) {
      localStorage.setItem("podetail", JSON.stringify(data))
      this.$router.push({ name: "apps-purchase-order-confirm-early-finish" })
    },
    async sendMessage() {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const Message = this.messageBox
      const newMessage = {
        user_id: userId,
        user_fullname: this.userdata.user_fullname,
        chat_text: Message,
        chat_created_time: moment(),
      }
      this.messagesList.push(newMessage)
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PURCHASE_ORDER_CHATS}`,
          {
            id: this.purchaseOrderId,
            chats: this.messagesList,
          },
          { headers },
        )
        .then(async response => {
          if (response.data.success === true) {
              this.$refs.refPurchaseOrderListTable.refresh()
              this.$refs.refPurchaseOrderListTableFinished.refresh()
              this.$refs.refPurchaseOrderListTableEarlyFinished.refresh()
              this.$refs.refPurchaseOrderListTableEarlyFinishedNC.refresh()
              this.$refs.refPurchaseOrderListTableVoid.refresh()
            // this.ref.messageListRef.refresh()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        }).finally(() => {
          this.messageBox = ""
        })
    },

    showDocuments(id) {
const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      this.purchaseOrderId = id
      this.modalUpload = true
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}po/${id}`,
          { headers },
        )
        .then(response => {
          if (response.data.success === true) {
            console.log("Fetching Data")
            if (response.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    handleFilesUpload(e) {
          this.file = e.target.files || e.dataTransfer.files
      },
    downloadAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            window.open(response.data.Payload, "_blank")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Download Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    deleteAttachment(document) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
      const FileName = document.document_file_name
      const ObjectName = `${document.document_number}${FileName}`
      const body = {
        ObjectName,
        FileName,
        id: document.document_id,
      }
      axios
        .post(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DELETE_ASSET}`,
          body,
          { headers },
        )
        .then(response => {
          if (response.data.Status === 1) {
            this.showDocuments(document.document_purchase_order_id)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Delete Attachment success",
                icon: "CheckIcon",
                variant: "success",
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.Message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
    },
    sendDocument() {
      const userToken = this.$cookies.get('userToken')
      const header = {
        'Content-Type': 'multipart/form-dataitem',
        Authorization: `Bearer ${userToken}`,
      }
      const formData = new FormData()
      if (typeof this.file[0] !== 'undefined') {
      formData.append('file', this.file[0], this.file[0].name)
      formData.append('document_purchase_order_id', this.purchaseOrderId)
      formData.append('time', moment())
    axios
      .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CREATE_DOCUMENT}`, formData, { headers: header })
      .then(async response => {
        if (response.data.success === true) {
            this.$refs.refPurchaseOrderListTable.refresh()
            this.$refs.refPurchaseOrderListTableFinished.refresh()
            this.$refs.refPurchaseOrderListTableEarlyFinished.refresh()
            this.$refs.refPurchaseOrderListTableEarlyFinishedNC.refresh()
            this.$refs.refPurchaseOrderListTableVoid.refresh()
            this.$refs.uploadFile.reset()
      axios
        .get(
          `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_DOCUMENTS}po/${this.purchaseOrderId}`,
          { headers: header },
        )
        .then(response2 => {
          if (response2.data.success === true) {
            if (response2.data.data) {
              this.documentsList.splice(0, this.documentsList.length)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Fetching Documents success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
              response2.data.data.map(value => {
                this.documentsList.push(value)
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Fetching Documents failed",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            })
          }
        })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Confirm Purchase Request Success',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Upload The Document!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  setup() {
    const BRANCH_APP_STORE_MODULE_NAME = "app-purchase-order"
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BRANCH_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(BRANCH_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewPurchaseOrderSidebarActive = ref(false)

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ]

    const {
      fetchPurchaseOrders,
      fetchPurchaseOrdersHO,
      fetchPurchaseOrdersFinished,
      fetchPurchaseOrdersEarlyFinished,
      fetchPurchaseOrdersEarlyFinishedNC,
      currentPageEarlyFinished,
      currentPageEarlyFinishedNC,
      perPageEarlyFinished,
      perPageEarlyFinishedNC,
      searchQueryEarlyFinished,
      searchQueryEarlyFinishedNC,
      sortByEarlyFinished,
      sortByEarlyFinishedNC,
      isSortDirDescEarlyFinished,
      isSortDirDescEarlyFinishedNC,
      startDateFilterEarlyFinished,
      endDateFilteEarlyrFinished,
      startDateFilterEarlyFinishedNC,
      endtDateFilterEarlyFinishedNC,
      dataMetaEarlyFinished,
      dataMetaEarlyFinishedNC,
      totalPurchaseOrderEarlyFinished,
      totalPurchaseOrderEarlyFinishedNC,
      fetchPurchaseOrdersFinishedHO,
      fetchPurchaseOrdersVoid,
      fetchRoles,
      tableColumns,
      tableColumnsVoid,
      perPage,
      perPageHO,
      perPageFinished,
      perPageFinishedHO,
      perPageVoid,
      currentPage,
      currentPageHO,
      currentPageFinished,
      currentPageFinishedHO,
      currentPageVoid,
      totalPurchaseOrder,
      totalPurchaseOrderHO,
      totalPurchaseOrderFinished,
      totalPurchaseOrderFinishedHO,
      totalPurchaseOrderVoid,
      dataMeta,
      dataMetaHO,
      dataMetaFinished,
      dataMetaFinishedHO,
      dataMetaVoid,
      perPageOptions,
      searchQuery,
      searchQueryHO,
      searchQueryFinished,
      searchQueryFinishedHO,
      searchQueryVoid,
      sortBy,
      sortByHO,
      sortByFinished,
      sortByFinishedHO,
      sortByVoid,
      isSortDirDesc,
      isSortDirDescHO,
      isSortDirDescFinished,
      isSortDirDescFinishedHO,
      isSortDirDescVoid,
      refPurchaseOrderListTable,
      refPurchaseOrderListTableHO,
      refPurchaseOrderListTableFinished,
      refPurchaseOrderListTableFinishedHO,
      refPurchaseOrderListTableEarlyFinished,
      refPurchaseOrderListTableEarlyFinishedNC,
      refPurchaseOrderListTableVoid,
      refetchData,

      // UI
      resolvePurchaseOrderRoleVariant,
      resolvePurchaseOrderRoleIcon,
      resolvePurchaseOrderStatusVariant,

      // Extra Filters
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterHO,
      endDateFilterHO,
      startDateFilterFinished,
      startDateFilterFinishedHO,
      startDateFilterVoid,
      endDateFilterFinished,
      endDateFilterFinishedHO,
      endDateFilterEarlyFinished,
      endDateFilterEarlyFinishedNC,
      endDateFilterVoid,
    } = PurchaseOrderList()

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ]

    return {
      to,
      cc,
      reason,
      messageBox,
      modalDetailShow,
      modalMessage,
      // Sidebar
      isAddNewPurchaseOrderSidebarActive,
      userRole,
      endDateFilterEarlyFinished,
      endDateFilterEarlyFinishedNC,
      fetchPurchaseOrders,
      fetchPurchaseOrdersHO,
      fetchPurchaseOrdersFinished,
      fetchPurchaseOrdersEarlyFinished,
      fetchPurchaseOrdersEarlyFinishedNC,
      searchQueryEarlyFinished,
      searchQueryEarlyFinishedNC,
      sortByEarlyFinished,
      sortByEarlyFinishedNC,
      isSortDirDescEarlyFinished,
      isSortDirDescEarlyFinishedNC,
      startDateFilterEarlyFinished,
      endDateFilteEarlyrFinished,
      startDateFilterEarlyFinishedNC,
      endtDateFilterEarlyFinishedNC,
      totalPurchaseOrderEarlyFinished,
      totalPurchaseOrderEarlyFinishedNC,
      dataMetaEarlyFinished,
      dataMetaEarlyFinishedNC,
      currentPageEarlyFinished,
      currentPageEarlyFinishedNC,
      perPageEarlyFinished,
      perPageEarlyFinishedNC,
      fetchPurchaseOrdersFinishedHO,
      fetchPurchaseOrdersVoid,
      fetchRoles,
      tableColumns,
      tableColumnsVoid,
      perPage,
      perPageHO,
      perPageFinished,
      perPageFinishedHO,
      perPageVoid,
      currentPage,
      currentPageHO,
      currentPageFinished,
      currentPageFinishedHO,
      currentPageVoid,
      totalPurchaseOrder,
      totalPurchaseOrderHO,
      totalPurchaseOrderFinished,
      totalPurchaseOrderFinishedHO,
      totalPurchaseOrderVoid,
      dataMeta,
      dataMetaHO,
      dataMetaFinished,
      dataMetaFinishedHO,
      dataMetaVoid,
      perPageOptions,
      searchQuery,
      searchQueryHO,
      searchQueryFinished,
      searchQueryFinishedHO,
      searchQueryVoid,
      sortBy,
      sortByHO,
      sortByFinished,
      sortByFinishedHO,
      sortByVoid,
      isSortDirDesc,
      isSortDirDescHO,
      isSortDirDescFinished,
      isSortDirDescFinishedHO,
      isSortDirDescVoid,
      refPurchaseOrderListTable,
      refPurchaseOrderListTableHO,
      refPurchaseOrderListTableFinished,
      refPurchaseOrderListTableFinishedHO,
      refPurchaseOrderListTableEarlyFinished,
      refPurchaseOrderListTableEarlyFinishedNC,
      refPurchaseOrderListTableVoid,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePurchaseOrderRoleVariant,
      resolvePurchaseOrderRoleIcon,
      resolvePurchaseOrderStatusVariant,

      roleOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      statusFilter,
      startDateFilter,
      endDateFilter,
      startDateFilterHO,
      endDateFilterHO,
      startDateFilterFinished,
      startDateFilterFinishedHO,
      startDateFilterVoid,
      endDateFilterFinished,
      endDateFilterFinishedHO,
      endDateFilterVoid,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss"
</style>
